<template>
  <div class="content content--home">
    <h1 class="title title--big title--theme title--indent">Мои задачи</h1>
    <app-cells position="start">
      <router-link :to="{ name: 'create-task' }" class="link">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="9" cy="9" r="9" fill="#2B93E7"/>
          <path
            d="M11.9694 9.67347H9.67347V11.9694C9.67347 12.2219 9.46684 12.4286 9.21429 12.4286C8.96173 12.4286 8.7551 12.2219 8.7551 11.9694V9.67347H6.45918C6.20663 9.67347 6 9.46684 6 9.21429C6 8.96173 6.20663 8.7551 6.45918 8.7551H8.7551V6.45918C8.7551 6.20663 8.96173 6 9.21429 6C9.46684 6 9.67347 6.20663 9.67347 6.45918V8.7551H11.9694C12.2219 8.7551 12.4286 8.96173 12.4286 9.21429C12.4286 9.46684 12.2219 9.67347 11.9694 9.67347Z"
            fill="white"/>
        </svg>
        <span>Добавить задачу</span>
      </router-link>
    </app-cells>
    <div class="content__title content__title--party">
      <div class="table-limiter">
        <div class="table-limiter__text">Количество записей на странице:</div>
        <div class="table-limiter__select">
          <dropdown class="my-dropdown-toggle"
                    :options="limitOptions"
                    :selected="limitSelected"
                    :closeOnOutsideClick="true"
                    @updateOption="updateLimiter"
          >
          </dropdown>
        </div>
      </div>
      <div class="table-limiter">
        <div class="table-limiter__text">
          Показаны записи <span>{{ returnEnd() > 0 ? returnStart() : 0 }} - {{ returnEnd() }}</span> из
          <span>{{ pagination.count }}</span>
        </div>
      </div>
    </div>
    <app-cells position="start">
      <button @click="onOpenSidebar" class="link" :class="{ 'filtered': filters.filtered }">
        <img src="@/assets/img/filter-icon.svg" alt="Фильтры">
        <span>Фильтры</span>
      </button>
    </app-cells>
    <div class="filters filters--indent filters--between">
      <div class="filters__block">
        <input
          @change="getList(1, limitSelected.name, status)"
          id="apps1"
          type="radio"
          name="status"
          value=""
          v-model="status"
        >
        <label for="apps1" class="filters__item">Все</label>

        <input
          @change="getList(1, limitSelected.name, status)"
          id="apps2"
          type="radio"
          name="status"
          value="3"
          v-model="status"
          checked
        >
        <label for="apps2" class="filters__item">Завершенные</label>

        <input
          @change="getList(1, limitSelected.name, status)"
          id="apps3"
          type="radio"
          name="status"
          value="1"
          v-model="status"
        >
        <label for="apps3" class="filters__item">Новые</label>

        <input
          @change="getList(1, limitSelected.name, status)"
          id="apps4"
          type="radio"
          name="status"
          value="2"
          v-model="status"
        >
        <label for="apps4" class="filters__item">В процессе</label>
        <input
          @change="getList(1, limitSelected.name, status)"
          id="apps5"
          type="radio"
          name="status"
          value="4"
          v-model="status"
        >
        <label for="apps5" class="filters__item">Просроченные задачи</label>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <v-client-table
        @row-click="onRowClick"
        :data="table.items"
        :columns="table.columns"
        :options="table.options"
        ref="tasksListTable"
        class="table-default table-default--dynamic table-default--minw"
      >
        <div slot="name" slot-scope="props" class="table-default__left">
          {{ props.row.name }}
        </div>

        <div slot="desc" slot-scope="props">
          <span v-html="props.row.desc"></span>
        </div>

        <div slot="workers" slot-scope="props">
          <span v-if="props.row.workers.executor.length" class="table-default__item">
            <span v-for="prop in props.row.workers.executor">
              <p class="table-default__title">Иполнитель: </p>
              <span>{{prop.full_name}}</span>
            </span>
          </span>

          <span v-if="props.row.workers.co_executor.length" class="table-default__item">
            <p class="table-default__title">Соисполнитель: </p>
            <span v-for="prop in props.row.workers.co_executor">
              {{prop.full_name}}
            </span>
          </span>

          <span v-if="props.row.workers.observer.length" class="table-default__item">
            <p class="table-default__title">Наблюдатель: </p>
            <span v-for="prop in props.row.workers.observer">
              {{prop.full_name}}
            </span>
          </span>
        </div>
      </v-client-table>
    </transition>
    <paginate
      v-if="pagination.pages > 1"
      v-model="pagination.page"
      :page-count="pagination.pages"
      :clickHandler="onClickPagination"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'pagination__item'"
      :page-link-class="'pagination__link'"
      :prev-class="'pagination__prev'"
      :next-class="'pagination__next'"
      :next-link-class="'pagination__link-next'"
      :prev-link-class="'pagination__link-prev'"
    />
    <app-sidebar-right
      title="Фильтры"
      :class="{'sidebar-manage--full': sidebar_open}"
      @close-sidebar="sidebar_open = false"
    >
      <form @submit.prevent>
        <app-form-group label="Участники">
          <app-select
            v-model="filters.user"
            :options="user_options"
            :reduce="status => status.id"
            :filterable="false"
            :searchable="true"
            label="id"
            placeholder="Выберите участника"
            class="select"
          >
            <template #open-indicator>
              <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                <path
                  d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                  fill="#fff"></path>
              </svg>
            </template>
            <template #no-options>Ничего не найдено</template>
            <template #option="option">
              <div class="select__item d-center">{{ option.full_name }}</div>
            </template>
            <template #selected-option="option">
              <div class="selected d-center">{{ option.full_name }}</div>
            </template>
          </app-select>
        </app-form-group>
        <app-form-group label="Дата начала">
          <app-input
            id="start_date"
            v-model="filters.start_date"
            v-mask="'99.99.9999'"
            placeholder="дд.мм.гггг"
            autocomplete="off"
          />
        </app-form-group>
        <app-form-group label="Дата окончания">
          <app-input
            id="end_date"
            v-model="filters.end_date"
            v-mask="'99.99.9999'"
            placeholder="дд.мм.гггг"
            autocomplete="off"
          />
        </app-form-group>
        <app-form-group label="Статус задачи">
          <app-checkbox
            v-model="filters.expired"
            id="4"
            name="input"
            label="Просроченная"
          />
        </app-form-group>
        <app-cells>
          <AppButton size="stretch" type="button" @click="applyFilters">Применить</AppButton>
        </app-cells>
        <app-cells v-if="filters.filtered">
          <app-button size="stretch" theme="transparent" type="button" @click="removeFilters">Сбросить</app-button>
        </app-cells>
      </form>
    </app-sidebar-right>
  </div>
</template>

<script>
import dropdown from 'vue-dropdowns';

export default {
  name: 'MyTasksList',
  components: {
    dropdown
  },
  data() {
    return {
      items: [
        {
          'title': 'Все',
          'type': 'all'
        },
        {
          'title': 'Завершенная',
          'type': 'completed'
        },
        {
          'title': 'Новая',
          'type': 'in-process'
        },
        {
          'title': 'В процессе',
          'type': 'profile-career'
        },
        {
          'title': 'Просроченные задачи',
          'type': 'overdue-tasks'
        },

      ],
      list: [],
      filter: 'all',
      table: {
        items: [],
        columns: ['id','name', 'desc', 'start_date', 'deadline', 'status_name', 'workers'],
        options: {
          headings: {
            id: 'ID',
            name: 'Наименование',
            desc: 'Описание',
            start_date: 'Дата начала',
            deadline: 'Крайний срок',
            status_name: 'Статус задачи',
            workers: '',
          },
          pagination: {show: false},
          sortable: [],
          perPage: 20,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Нет подходящих записей',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback: item => `table-default__row table-default__row--${item.status}`,
        },
      },
      pagination: {
        pages: 1,
        page: 1,
        count: 0,
        prevLink: '',
        nextLink: ''
      },
      table_limit: 20,
      limitSelected: {name: 20},
      limitOptions: [
        {name: 20},
        {name: 40},
        {name: 60},
        {name: 80},
        {name: 100},
      ],
      status: '',
      user_options: [],
      once: false,
      filters: {
        user: null,
        start_date: null,
        end_date: null,
        expired: false,
        filtered: false
      },
      sidebar_open: false,
    }
  },
  created() {
    this.filters = JSON.parse(localStorage.getItem('filters')) || this.filters
    this.$store.dispatch('users/GET_DATA')
      .then(response => {
        this.user_options = response.data
      })
  },
  mounted() {
    this.getList(1, this.limitSelected.name)
  },
  methods: {
    getList(page = 1, page_size = 20, status, search) {
      this.pagination.page = page
      let filters = this.normalizeFilters();
      if (!this.filters.filtered) {
        filters = {
          user: '',
          start_date: '',
          end_date: '',
          expired: ''
        };
      }
      this.$store.dispatch('profile/tasks/GET_DATA', { page, page_size, status, search, filters })
        .then(response => {
          this.table.items = response.data.results
          console.log(this.table.items);
          this.pagination.pages = response.data.pages
          this.pagination.count = response.data.count
          this.table.items.forEach((item, i) => {
            item.workers = {
              observer: {},
              executor: {},
              co_executor: {}
            }
            if(item.observer.length) {
              item.workers.observer = item.observer
            }
            if(item.executor.length) {
              item.workers.executor = item.executor
            }
            if(item.co_executor.length) {
              item.workers.co_executor = item.co_executor
            }
          })
        })
    },
    normalizeFilters() {
      const normalizedFilters = {}
      normalizedFilters.user = this.filters.user || ''
      normalizedFilters.start_date = this.filters.start_date || ''
      normalizedFilters.end_date = this.filters.end_date || ''
      normalizedFilters.expired = this.filters.expired || ''
      return normalizedFilters
    },
    updateLimiter(data) {
      this.limitSelected.name = data.name
      this.$refs.tasksListTable.setLimit(data.name)
      this.getList(this.pagination.page, this.limitSelected.name, this.status)
    },
    returnStart() {
      return (this.pagination.page * this.table.items.length) - (this.table.items.length - 1)
    },
    returnEnd() {
      return this.returnStart() + this.table.items.length - 1
    },
    onClickPagination(page) {
      this.getList(page, this.table_limit)
    },
    onRowClick(row) {
      this.$router.push({ name: 'task', params: { id: row.row.id } })
    },
    onOpenSidebar() {
      this.sidebar_open = true
    },
    applyFilters() {
      this.filters.filtered = true;
      this.getList()
      this.sidebar_open = false
      localStorage.setItem('filters', JSON.stringify(this.filters))
    },
    removeFilters() {
      this.filters.user = null
      this.filters.start_date = null
      this.filters.end_date = null
      this.filters.expired = false
      this.filters.filtered = false;
      this.getList();
      this.sidebar_open = false
      localStorage.removeItem('filters')
    },
  },

}
</script>
